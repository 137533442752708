import { Footer } from "antd/es/layout/layout";
import { useState } from "react";
import { About } from "../components/About/About";
import AdvantagesSection from "../components/AdvantagesSection/AdvantagesSection";
import EmailSectionSubmit from "../components/EmailSectionSubmit/EmailSectionSubmit";
import Services from "../components/Services/Services";
import { aboutItems } from "../dummyData";

function MainScreen() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(true);

  return (
    <div className="App" id="home">
      <EmailSectionSubmit
        hasSubscribed={hasSubscribed}
        setHasSubscribed={setHasSubscribed}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Services />
      <About
        items={aboutItems}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      {hasSubscribed ? <AdvantagesSection /> : ""}
      <Footer
        hasSubscribed={hasSubscribed}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}

export default MainScreen;
