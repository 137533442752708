import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./About.scss";
import { TitleLogo } from "../../assets";
import greenPlant from "../../assets/AboutImage/GreenPlant1.png";
import ContactsUsModal from "./ContactsUsModal/ContactsUsModal";

export const About = ({ items, isModalOpen,setIsModalOpen }) => {

  return (
    <>
    <div className="aboutMain_Container" id="about">
      <img className="aboutUsImg" alt="Carousel Images" src={items.image} />
      <div className="about_rightContainer">
        <div className="aboutTitle_container">
        <div className="aboutTitleText">
          {<TitleLogo />}
            <span>{items.title}</span>
            <span className="usTitleText">&nbsp;Us</span>
        </div>

        </div>
        <div className="aboutDescTextMain_Container">
        <span className="aboutDescText">{items.description1}</span>
        <span className="aboutDescText">{items.description2}</span>
        </div>
        <div className="rightContainer_footer">
            <button className="rightContanier_footerBtn" onClick={()=>setIsModalOpen(true)}>Contact Us</button>
            <span className="divider-Btn"></span>
        </div>
      </div>
      <img src={greenPlant} alt="Green Plant" className="aboutGreenPlant"/>
    </div>
      <ContactsUsModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
    </>

  );
};
