import firebase from "firebase/compat/app"
import {getDatabase} from "@firebase/database"
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: "AIzaSyDq7OtGRkfSIxdcQTfjletzIc0y-RKZVqg",
  authDomain: "dibbery-landing.firebaseapp.com",
  databaseURL: "https://dibbery-landing-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dibbery-landing",
  storageBucket: "dibbery-landing.appspot.com",
  messagingSenderId: "234483426097",
  appId: "1:234483426097:web:801f5c56e970470e570610",
  measurementId: "G-XKN4600L01"
};


if(firebase.apps.length === 0) {
 var app  =  firebase.initializeApp(firebaseConfig)
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Le7JPooAAAAAIH-JHEKTCAVZM3hlBR186XIJOas'),
});

export const db = getDatabase()