import React, { useEffect } from "react";

export const links = {
  iPhone: "https://apps.apple.com/app/dibbery/id6479527115",
  android:
    "https://play.google.com/store/apps/details?id=com.horizontlabs.dibbery",
  other: "/?from=Other",
};

export default function GetApp() {
  useEffect(() => {
    const uagent = navigator.userAgent;
    const isAndroid = uagent.match(/Android/i);
    const isIphone = uagent.match(/iPhone/i);

    window.location = isIphone
      ? links.iPhone
      : isAndroid
      ? links.android
      : links.other;
  }, []);

  return <b>Loading...</b>;
}
