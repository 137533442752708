import React, { useState } from "react";
import "./Services.scss";
import { serviceItems } from "../../dummyData";

const Services = () => {
  const [openCard, setOpenCard] = useState(false);

  const AddText = `Be the first to get dibs on top-quality gardening products. Join Dibbery's waitlist for exclusive access to essential tools, seeds, and more.`;

  const animateCard = (serviceId) => {
    setOpenCard(serviceId);
    const cardItself = document.getElementById(`services-card-${serviceId}`);
    const cardWidth = cardItself.offsetWidth;
    const cardHeight = cardItself.offsetHeight;
    console.log(cardHeight, cardWidth);
    const { left, top } = cardItself.getBoundingClientRect();
    const { clientWidth, clientHeight } = document.documentElement;
    console.log(clientWidth, "width");
    const leftOffset = clientWidth / 2 - left - cardWidth / 2;
    const topOffset = clientHeight / 2 - top - cardHeight / 2;
    cardItself.style.translate = `${leftOffset}px ${topOffset}px`;
    if (clientWidth <= 460) {
      cardItself.style.scale = 3.9;
    } else {
      cardItself.style.scale = 2.5;
    }
    cardItself.style.zIndex = 9999;
  };
  const animateReverseCard = (serviceId) => {
    const cardItself = document.getElementById(`services-card-${serviceId}`);
    if (cardItself) {
      cardItself.style.translate = `0px 0px`;
      cardItself.style.scale = `1`;
      cardItself.style.zIndex = 0;
    }
  };

  const handleOpen = (itemName, index) => {
    const name = itemName.replace(/\s+/g, "-").toLowerCase();
    if (name !== null) {
      if (openCard === name) {
        closeCard();
      } else {
        animateCard(name);
      }
    }
  };

  const closeCard = () => {
    animateReverseCard(openCard);
    setOpenCard(null);
  };

  return (
    <>
      {openCard && <div className="backdrop" onClick={closeCard} />}
      <div className="service-container" id="services">
        <div className="image-mainContainer">
          {serviceItems?.map(({ itemImg, itemName, desc }, index) => {
            return (
              <div
                className={
                  openCard &&
                  itemName.replace(/\s+/g, "-").toLowerCase() === openCard
                    ? "image-container-open"
                    : "image-container"
                }
                key={index}
                id={`services-card-${itemName
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
                onClick={() => handleOpen(itemName, index)}
              >
                <img
                  className={
                    openCard &&
                    itemName.replace(/\s+/g, "-").toLowerCase() === openCard
                      ? "custom-avt-open"
                      : "custom-avt"
                  }
                  src={itemImg}
                  alt={itemName}
                  id="custom-avt"
                />
                <span
                  className={
                    openCard &&
                    itemName.replace(/\s+/g, "-").toLowerCase() === openCard
                      ? "image-Text-open"
                      : "image-Text"
                  }
                  id="img-text"
                >
                  {openCard &&
                  itemName.replace(/\s+/g, "-").toLowerCase() === openCard ? (
                    <>
                      {itemName}
                      <br />
                      {desc}
                    </>
                  ) : (
                    <>
                      {itemName}
                      <br />
                    </>
                  )}
                  <br />
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Services;
