import ReactGa from "react-ga";

const TRACKING_ID = "G-XKN4600L01";

const InitializeGoogleAnalytics = () => {
  ReactGa.initialize(TRACKING_ID);
  ReactGa.pageview(window.location.pathname);
};

const TrackGoogleAnalyticsEvent = (
  category = "",
  action = "",
  label = ""
) => {
    ReactGa.event({
        category: category,
        action:action,
        label: label
    })
};

export default InitializeGoogleAnalytics;
export{InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent}