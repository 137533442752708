import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { ref, set, get, orderByChild, equalTo, query } from "firebase/database";

export default function Stats() {
  const [data, setData] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const password = "T0Saproject_23";
  useEffect(() => {
    const q = query(ref(db, "subscribers/"), orderByChild("id"));
    get(q)
      .then((snapshot) => {
        const values = snapshot.val();
        const emails = Object.values(values).map((vals) => vals.email);
        setData(emails);
      })
      .catch((error) => {
        console.error("Error fetching email:", error);
      });
  }, []);

  return (
    <div>
      <h1>Stats</h1>
      {isValid ? (
        <>
          <h1>Number of subscriptions: {data.length}</h1>
          <textarea
            value={data.join(", ")}
            style={{ width: 400, height: 400 }}
          />
        </>
      ) : (
        <>
          <h1>Enter password</h1>
          <input onChange={(e) => setIsValid(e.target.value === password)} />
        </>
      )}
    </div>
  );
}
