import React from "react";
import "./AdvantagesSection.scss";
import { AdvantageIcon } from "../../assets";
import { Card } from "antd";
import { AdvantagesItems } from "../../dummyData";

const AdvantagesSection = () => {
  return (
    <div className="AdvantagesMain_Container" id="advantages">
      <div className="AdvantagesLeft_Container">
        <div className="AdvantagesTitle">
          <span>{<AdvantageIcon />}</span>
          <span className="firtPartTitle">Our</span>
          <span className="secondPartTitle">{AdvantagesItems.title}</span>
        </div>
        <div className="leftCardMain_Container">
          {AdvantagesItems.card.map((item, index) => (
            <Card
              key={index}
              title={item.title}
              bordered={false}
              className="Card_Container"
            >
              <span className="cardIcon_Container">{item.icon}</span>
              <p className="cardBodyDescp">{item.description}</p>
            </Card>
          ))}
        </div>
      </div>
      <div className="AdvantagesRight_Container">
        <img
          className="rightPicture_Container"
          src={AdvantagesItems.image}
          alt=""
        />
      </div>
    </div>
  );
};

export default AdvantagesSection;
