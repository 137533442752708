import React, { useRef, useState } from "react";
import "./ContactsUsModal.scss";
import Modal from "antd/es/modal/Modal";
import { Button, notification } from "antd";
import emailjs from "@emailjs/browser";
import contactUsimg from "../../../assets/contactUsImg.png";
import {ref, set} from "firebase/database"
import { db } from "../../../config/firebase";
import uuid from "react-uuid";
import ContactFromCategory, { ContactFormCompletedAction } from "../../../utils/google-analytics/events/ContactForm";
import { TrackGoogleAnalyticsEvent } from "../../../utils/google-analytics";

let re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactsUsModal = ({ isModalOpen, setIsModalOpen }) => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleOk = () => {
    setIsModalOpen(false);
    handleReset()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    handleReset()
  };

  const handleReset = () => {
    setName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (re.test(email) && name !== "" && message !== "") {
      //set data to realTime Database
      set(ref(db, "usersContactFrom/" + uuid()), {
        nameContact: name,
        emailContact: email,
        messageContact: message 
        })
      //send email 
      emailjs
        .sendForm(
          "service_sez76xq",
          "template_k4u5qcf",
          form.current,
          "bm7zPgN6uMdlzP5is"
        )
        .then((result) => {
          notification.success({
            message: "Email sent successfully",
          });
        })
        .catch((error) => {
          notification.error({
            message: "Email failed to send",
          });
        });
        TrackGoogleAnalyticsEvent(
          ContactFromCategory,
          ContactFormCompletedAction,
          window.location.pathname
        );
      handleCancel();
      handleReset();
    } else {
      if (name === "") {
        notification.error({
          message: "Enter a name",
        });
      } else if (message === "") {
        notification.error({
          message: "Write a message",
        });
      } else {
        notification.error({
          message: `Invalid email address`,
        });
      }
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      className="contactUsModal"
      footer={false}
      centered
    >
      <>
        <div className="contactUsHeader">
          <h3 className="firstHeaderText">Contact Us</h3>
          <h2 className="secondHeaderText">Send Us Your Message.</h2>
        </div>
        <div className="contactUsModal_BodyContainer">
          <form ref={form} className="contactUsFormInput">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="contactUsNameInput"
              placeholder="Your Name"
              type="text"
              name="name"
              required={true}
            />
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="contactUsEmailInput"
              placeholder="E-mail"
              type="email"
              required
              name="email"
            />
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="contactUsMessageInput"
              placeholder="Text Message*"
              name="message"
            />
            <div className="contactUsBtnSubmit">
              <Button onClick={sendEmail} type="submit" value="Send" className="sendMessage">
                Send Message
              </Button>
            </div>
          </form>
         
          <img
            src={contactUsimg}
            alt="Modal Img"
            className="contactUsModalImage"
          />
        </div>
      </>
    </Modal>
  );
};

export default ContactsUsModal;
