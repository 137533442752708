import Lottie from 'react-lottie';
// import animationDataFlower from "../../assets/flower.json"
// import animationDataSunFlower from "../../assets/LoaderSunFlower.json"
import animationDataSunFlower1 from "../../assets/LoadeSunFlower.json"
import "./LoadingOverlay.scss"

const LoadingOverlay = () => {
  
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationDataSunFlower1,
        rendererSettings: {
          preserveAspectRatio: 'xMaxYMax slice'
        }
      };
    return (
      <div className="loading-overlay">
        <div className="overlay-content">
          <Lottie  options={defaultOptions} className="animation" width={150} height={100}/>
        </div>
      </div>
    );
  };
  
  export default LoadingOverlay; 