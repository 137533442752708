import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// {
//   "rules": {
//     "subscribers": {
//       ".read": "true",
//       ".write": "true",
//       ".indexOn": ["email", "id"]
//     },
//     "usersContactFrom": {
//       	".read": "false", 
//      		 ".write": "true",
//   }
// }
// }

