import aboutUsImg from "./assets/AboutImage/woman-pours-water-into-flower-pot-she-cares-house-plants-young-woman-watering-her-plants_1212-3594 1.png";
import {
  OrderCartIcon,
  TimeDeliveryIcon,
  ListOfOrderIcon,
} from "./assets/AdvantagesIcons/index";
import advantageImg from "./assets/AdvantagesIcons/AdvantageImg.png";
import advantageImg1 from "./assets/AdvantagesIcons/AdvantageImg1.png";
import {
  InstagramLogo,
  EmailLogo,
  TwitterLogo,
} from "./assets/FooterIcon/Index";
import product from "./assets/Service/product.png";
import service from "./assets/Service/service.png";
import community from "./assets/Service/community.png";
import EarnWithDibbery from "./assets/Service/EarnWithDibbery.png";

export const serviceItems = [
  {
    itemName: "Earn with Dibbery",
    itemImg: EarnWithDibbery,
    id: 1,
    desc: "On Dibbery, sellers can offer products and services to boost their income. Join now for a thriving business and financial control.",
  },
  {
    itemName: "Products",
    itemImg: product,
    id: 2,
    desc: "Be the first to get dibs on top-quality gardening products. Join Dibbery's waitlist for exclusive access to essential tools, seeds, and more.",
  },
  {
    itemName: "Services",
    itemImg: service,
    id: 3,
    desc: "Connect with skilled gardeners or showcase your expertise. Dibbery's Service Channel is where gardening know-how meets those in need of professional help.",
  },
  {
    itemName: "Community",
    itemImg: community,
    id: 4,
    desc: "Dive into Dibbery's gardening community. Share tips, connect with enthusiasts, and find inspiration for your green endeavors. It's your digital hub for all things gardening.",
  },
];

export const aboutItems = {
  id: "about",
  title: "About",
  description1: `We're a passionate 
team of garden enthusiasts, digital dreamers, and nature lovers. 
Our shared love for all things green has driven us to create something truly extraordinary - a 
gardening hub like no other.`,
  description2: `
In the world of Dibbery, finding the ideal gardener is as effortless
as harvesting herbs, every gardening product is within reach, 
and a vibrant gardening community shares wisdom and inspiration.`,
  image: aboutUsImg,
  btnAction: () => {},
};

export const AdvantagesItems = {
  id: "advantages",
  title: "Advantages",
  card: [
    {
      title: "Exclusive Access",
      description: `Be among the first to explore Dibbery's Service Channel, where professional gardeners offer their expertise, and our Product Channel, where you can find the best gardening products. Get ahead of the crowd and secure your spot!`,
      icon: <OrderCartIcon />,
    },
    {
      title: "Dynamic Gardening Community",
      description: `Dibbery's Community Channel is your space to connect with fellow gardeners, share your green thumb triumphs, and get inspired by reels, articles, videos, and more. It's the social media for every type of gardener.`,
      icon: <TimeDeliveryIcon />,
    },
    {
      title: "Unlock the Green Future",
      description: `Dibbery is more than a platform; it's a growing movement dedicated to making gardening accessible, enjoyable, and rewarding. By downloading our app, you're becoming a pioneer in this green revolution.`,
      icon: <ListOfOrderIcon />,
    },
  ],
  backgroundImage: advantageImg,
  image: advantageImg1,
};

export const footerItems = {
  id: "footer",
  title: "Footer",
  socialMediaIcons: [
    {
      link: "mailto:dibbery@horizontlabs.com",
      logo: <EmailLogo />,
      name: "Email",
    },
    {
      link: "https://instagram.com/the_dibbery",
      logo: <InstagramLogo />,
      name: "Instagram",
    },
    {
      link: "https://x.com/The_Dibbery",
      logo: <TwitterLogo />,
      name: "Twitter",
    },
  ],
  navList: [
    { id: "home", name: "Home", links: "#home" },
    { id: "about", name: "About", links: "#about" },
    { id: "advantages", name: "Advantages", links: "#advantages" },
  ],
  otherInfo: ["Privacy Policy", "Terms of Use"],
};
