import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Support = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send the data to an API)
    console.log(formData);
    // Reset the form
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Dibbery FAQ
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What is Dibbery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dibbery is a social network designed for gardeners to connect, share
            tips, and grow their gardening knowledge together.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>How do I join Dibbery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can join Dibbery by signing up on our website with your email
            address and creating a profile. It's free to join!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Can I share my gardening tips on Dibbery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutely! Dibbery is all about sharing knowledge and connecting
            with fellow gardeners. You can post your tips, photos, and more.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Is Dibbery available on mobile devices?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, Dibbery is accessible through web browsers on both desktop and
            mobile devices. A dedicated mobile app is coming soon!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Typography variant="h4" gutterBottom style={{ marginTop: "40px" }}>
        Contact Us
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
        >
          Send Message
        </Button>
      </form>
    </div>
  );
};

export default Support;
