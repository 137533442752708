import {
  BrowserRouter,
  Link,
  Route,
  Routes,
} from "react-router-dom";
import Stats from "./screens/Stats";
import MainScreen from "./screens/MainScreen";
import GetApp from "./screens/GetApp";
import Support from "./screens/Support";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/get-app" element={<GetApp />} />
        <Route path="/support" element={<Support />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
