import React, { useEffect, useState } from "react";
import "./EmailSectionSubmit.scss";
import { Button, Space } from "antd";
import { useMediaQuery } from "react-responsive";
import { notification } from "antd";
import { ref, set, get, orderByChild, equalTo, query } from "firebase/database";
import { db } from "../../config/firebase";
import uuid from "react-uuid";
import InitializeGoogleAnalytics, {
  TrackGoogleAnalyticsEvent,
} from "../../utils/google-analytics";
import {
  NewUserCompletedAction,
  NewUserCategory,
} from "../../utils/google-analytics/events/NewUser";
import {
  ExistingUserCompletedAction,
  ExistingUserCategory,
} from "../../utils/google-analytics/events/ExistingUser";
import ReferAFriendCategory, {
  ReferAFriendCompletedAction,
} from "../../utils/google-analytics/events/ReferAFriend";
import axios from "axios";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { NavLogo } from "../../assets";
import PhoneIcon from "../../assets/Phone.png";
import Lottie from "react-lottie";
import animationDataScroll from "../../assets/animationDataScroll.json";
import { links } from "../../screens/GetApp";

let re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailSectionSubmit = ({ hasSubscribed, setHasSubscribed }) => {
  const [emailSubmit, setEmailSubmit] = useState("");
  const [userId, setUserId] = useState("");
  const [refId, setRefId] = useState("");
  const [referralEmail, setReferralEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const emailHandleChange = (e) => {
    const emailValue = e.target.value;
    setEmailSubmit(emailValue);
  };

  console.log(userId);
  console.log(refId);

  const sendEmailToUser = async (email) => {
    axios
      .post(
        "https://dibbery.herokuapp.com/sendwelcomeemail",
        {
          from: "dibbery@horizontlabs.com",
          to: email,
          templateID: 5233977,
          subject: "You are on the list",
          UNSUB_LINK_EN: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => console.log(res.request, "ervis"))
      .catch((err) => console.log(err));
  };

  const loadReferralId = () => {
    let id = localStorage.getItem("refId");
    if (id === null) {
      // Get refId from url
      const urlSearchParams = new URLSearchParams(window.location.search);
      const refId = urlSearchParams.get("ref");
      if (refId) {
        getMailFromRefId(refId)
          .then((email) => {
            setReferralEmail(email);
          })
          .catch((error) => {
            console.log(error);
          });
        // Store the refId in localStorage
        localStorage.setItem("refId", refId);
        setRefId(refId);
      }
    }
  };

  const getMailFromRefId = (referral) => {
    const q = query(
      ref(db, "subscribers/"),
      orderByChild("id"),
      equalTo(referral)
    );

    return get(q)
      .then((snapshot) => {
        console.log(snapshot.val());
        const referralEmail = snapshot.val()[referral]["email"];
        return referralEmail;
      })
      .catch((error) => {
        console.error("Error fetching email:", error);
        throw error; // You can handle the error as needed
      });
  };

  const emailHandleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const uniqueId = uuid();
    // console.log("refEmail", referralEmail)
    let storeData = {
      email: emailSubmit,
      refId: referralEmail,
      id: uniqueId,
    };

    const q = query(
      ref(db, "subscribers/"),
      orderByChild("email"),
      equalTo(emailSubmit)
    );
    if (re.test(emailSubmit)) {
      //add data to realTime Database
      get(q)?.then((snapshot) => {
        setTimeout(() => {
          setIsLoading(false);
          setHasSubscribed(true);
        }, 2000);
        if (snapshot.exists()) {
          snapshot.forEach((childSnapshoot) => {
            setTimeout(() => {
              notification.info({
                message: "This email already exists",
              });
            }, 2500);
          });
          TrackGoogleAnalyticsEvent(
            ExistingUserCategory,
            ExistingUserCompletedAction,
            window.location.pathname
          );
        } else {
          sendEmailToUser(emailSubmit);
          set(ref(db, "subscribers/" + uniqueId), storeData);
          TrackGoogleAnalyticsEvent(
            NewUserCategory,
            NewUserCompletedAction,
            window.location.pathname
          );
        }
      });
      localStorage.setItem("emailSubmit", JSON.stringify(storeData));
      setEmailSubmit("");
    } else {
      setIsLoading(false);
      notification.error({
        message: "Invalid email address",
      });
    }
  };

  const copyURLToClipboard = async () => {
    await navigator.clipboard
      .writeText(
        `${`${window.location.origin}/${
          userId !== undefined ? `?ref=${userId}` : ""
        }`}`
      )
      .then(() => {
        TrackGoogleAnalyticsEvent(
          ReferAFriendCategory,
          ReferAFriendCompletedAction,
          window.location.pathname
        );
        notification.success({
          message: "URL copied to clipboard",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Failed to copy URL",
        });
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataScroll,
    rendererSettings: {
      preserveAspectRatio: "xMaxYMax slice",
    },
  };

  useEffect(() => {
    InitializeGoogleAnalytics();
    loadReferralId();
    const storedEmail = localStorage.getItem("emailSubmit");
    if (storedEmail) {
      setEmailSubmit(JSON.parse(storedEmail));
    }
    if (storedEmail) {
      setUserId(JSON.parse(storedEmail).id);
    }
    const storedRefId = localStorage.getItem("refId");
    if (storedRefId) {
      setRefId(storedRefId);
    }
  }, [hasSubscribed]);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <section className="descriptionMain_Container" id="section">
        <NavLogo className="navbar_logo" />
        <span className="logo-text">Dibbery</span>
        <span className="firstTextDescp">One Stop Destination For Your</span>
        <span className="secondTextDescp">Gardening Desires</span>
        <span className="additional-text">
          Grow smarter with our AI Horticulture Platform, blending technology
          with gardening for better plant care and growth
        </span>
        {!hasSubscribed ? (
          <>
            <Space.Compact className="emailInputSubmit" id="email-input-submit">
              <input
                value={emailSubmit}
                onChange={emailHandleChange}
                className="emailInput"
                placeholder="Your e-mail"
                pattern=".+@beststartupever\.com"
              />
              <button
                type="primary"
                onClick={emailHandleSubmit}
                className="submit-email"
              >
                GET EARLY ACCESS
              </button>
            </Space.Compact>
          </>
        ) : (
          <>
            <div className="store-buttons">
              <a
                href={links.android}
                target="_blank"
                rel="noreferrer"
                tabIndex="0"
              >
                <img
                  class="bn45"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                  alt="bn45"
                />
              </a>
              <a
                href={links.iPhone}
                tabIndex="0"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="bn46"
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  alt="bn45"
                />
              </a>
            </div>
          </>
        )}
        <img src={PhoneIcon} className="phone-icon" />
        <Lottie
          options={defaultOptions}
          width={isMobile ? 61 : 71}
          height={isMobile ? 61 : 71}
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        />
      </section>
    </>
  );
};

export default EmailSectionSubmit;
